<template>
  <b-container
    fluid
    class="auth-wrapper "
  >
    <b-row class="justify-content-md-center ">
      <b-col md="8">

        <!-- Login v1 -->
        <b-card
          class="mb-0 my-5 "
        >
          <form-wizard
            color="#7367F0"
            title="Formulaire d'inscription"
            subtitle="Rejoignez nous pour profitez des opportunités que vous offre la plateforme"
            finish-button-text="Submit"
            back-button-text="Previous"
            class="steps-transparent mb-3 font-size"
            justified
            @on-complete="formSubmitted"
          >
            <!-- account detail tab -->
            <tab-content>
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Conditions d'utilisation
                  </h5>
                  <small class="text-muted">
                    Accepter nos conditions d'utilisation.
                  </small>
                </b-col>
                <b-col md="12">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      rounded
                      size="42"
                      variant="light-primary"
                      class="mr-1"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="20"
                      />

                    </b-avatar>
                    <div>
                      <h4 class="mb-0">
                        Conditions d'utilisation de la plateforme Bourse de frêt
                      </h4>
                      <span>Which license do I need?</span>
                    </div>
                  </div>

                  <!-- collapse -->
                  <app-collapse
                    id="faq-payment-qna"
                    accordion
                    type="margin"
                    class="mt-2"
                  >

                    <app-collapse-item
                      title="Where has my order reached?"
                    >
                      Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.
                    </app-collapse-item>

                  </app-collapse>
                  <!-- collapse -->
                  <app-collapse
                    id="faq-payment-qna"
                    accordion
                    type="margin"
                    class="mt-2"
                  >

                    <app-collapse-item
                      title="Where has my order reached?"
                    >
                      Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.
                    </app-collapse-item>

                  </app-collapse>
                  <!-- collapse -->
                  <app-collapse
                    id="faq-payment-qna"
                    accordion
                    type="margin"
                    class="mt-2"
                  >

                    <app-collapse-item
                      title="Where has my order reached?"
                    >
                      Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.
                    </app-collapse-item>

                  </app-collapse>

                  <!--/ collapse -->
                  <b-form-checkbox
                    v-model="selected"
                    value="A"
                    class="custom-control-primary mt-2"
                  >
                    Lu et accepté
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </tab-content>

            <!-- personal details -->
            <tab-content>
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Information personnelle
                  </h5>
                  <small class="text-muted">Entrer vos informations personnelles.</small>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-for="nom"
                    label="Nom"
                  >
                    <b-form-input
                      id="nom"
                      :formatter="formatter"
                      placeholder="John"
                      v-model="lastname"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Prénom (s)"
                    label-for="prenom"
                  >
                    <b-form-input
                      id="prenom"
                      :formatter="formatter"
                      placeholder="Doe"
                      v-model="firstname"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Adresse"
                    label-for="adresse"
                  >
                    <b-form-input
                      id="adresse"
                      :formatter="formatter"
                      placeholder="Cotonou"
                      v-model="address"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      type="email"
                      placeholder="mameribj@gmail.com"
                      v-model="email"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Téléphone"
                    label-for="telephone"
                  >
                    <b-form-input
                      id="telephone"
                      type="telephone"
                      placeholder="+229 97000000"
                      v-model="phone"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>

            <!-- address -->
            <tab-content>
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Informations sociétés
                  </h5>
                  <small class="text-muted">Entrer les informations de votre société.</small>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Nom de la société"
                    label-for="raison_sociale"
                  >
                    <b-form-input
                      id="raison_sociale"
                      placeholder="98 Borough bridge Road, Birmingham"
                      v-model="raison_sociale"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="N° IFU"
                    label-for="ifu"
                  >
                    <b-form-input
                      id="ifu"
                      v-model="ifu"

                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Téléphone"
                    label-for="telephone"
                  >
                    <b-form-input
                      id="telephone"

                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    label-for="email"
                    
                  >
                    <b-form-input
                      id="email"
                      v-model="email_society"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Pays"
                    label-for="pays"
                  >
                    <b-form-input
                      id="pays"
                      v-model="country_society"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Ville"
                    label-for="ville"
                  >
                    <b-form-input
                      id="ville"
                      v-model="state_society"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>

            <!-- social link -->
            <tab-content>
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Pièces Justicatives
                  </h5>
                  <small class="text-muted">Charger pièces justicatives</small>
                </b-col>
                <b-col md="6">
                  <label for="cni">CNI</label>
                  <b-form-file
                    id="cni"
                    accept=".jpg, .png, .jpeg"
                      v-model="cni"
                  >
                    <template
                      slot="file-name"
                      slot-scope="{ names }"
                    >
                      <b-badge variant="primary">
                        {{ names[0] }}
                      </b-badge>
                      <b-badge
                        v-if="names.length > 1"
                        variant="dark"
                        class="ml-1"
                      >
                        + {{ names.length - 1 }} More files
                      </b-badge>
                    </template>
                  </b-form-file>
                </b-col>
                <b-col md="6">
                  <label for="permis_conduire">Permis de conduire</label>
                  <b-form-file
                    id="permis_conduire"
                    accept=".jpg, .png, .jpeg"
                      v-model="permis_conduire"
                  >
                    <template
                      slot="file-name"
                      slot-scope="{ names }"
                    >
                      <b-badge variant="primary">
                        {{ names[0] }}
                      </b-badge>
                      <b-badge
                        v-if="names.length > 1"
                        variant="dark"
                        class="ml-1"
                      >
                        + {{ names.length - 1 }} More files
                      </b-badge>
                    </template>
                  </b-form-file>
                </b-col>
                <b-col md="6">
                  <label for="carte_importateur">Carte d'importateur</label>
                  <b-form-file
                    id="carte_importateur"
                    accept=".jpg, .png, .jpeg"
                      v-model="carte_importateur"
                  >
                    <template
                      slot="file-name"
                      slot-scope="{ names }"
                    >
                      <b-badge variant="primary">
                        {{ names[0] }}
                      </b-badge>
                      <b-badge
                        v-if="names.length > 1"
                        variant="dark"
                        class="ml-1"
                      >
                        + {{ names.length - 1 }} More files
                      </b-badge>
                    </template>
                  </b-form-file>
                </b-col>
              </b-row>
            </tab-content>
            <tab-content>
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Mot de passe
                  </h5>
                  <small class="text-muted">Créer votre mot de passe</small>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Mot de passe"
                    label-for="password"
                  >
                    <b-form-input
                      id="password"
                      placeholder=""
                      type="password"
                      v-model="password"                      
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Confirmation de mot de passe"
                    label-for="confirm_password"
                  >
                    <b-form-input
                      id="confirm_password"
                      placeholder=""
                      type="password"
                      v-model="confirm_password"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>
          </form-wizard>

        </b-card>
      <!-- /Login v1 -->
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'


import {
  ref, onMounted, reactive, computed,
} from '@vue/composition-api'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BContainer,
  BAvatar,
  BFormCheckbox,
  BFormFile,
  BBadge,
} from 'bootstrap-vue'
import { codeIconInfo } from './code'

export default {
  components: {
    FormWizard,
    TabContent,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BContainer,
    BAvatar,
    AppCollapseItem,
    AppCollapse,
    BFormCheckbox,
    BFormFile,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  setup(){
      const selectedContry='select_value'
      const selectedLanguage= 'nothing_selected'
      const codeIconInfo=''
      const countryName= [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ]
      const languageName= [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ]
     

  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    formatter(value) {
      return value.toLowerCase()
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.font-size{
  font-size: 9px !important;
}
body, html{
  height: 100%;
  margin: 0;

}
.auth-wrapper {
 background-image: url(../../assets/images/banner1.jpg);
 background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
/* background-repeat: no-repeat; */
}
</style>
